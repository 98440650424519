import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Button from 'atoms/button'
import PardotResponsive from 'organisms/pardot-responsive'
import { getCategoryPath } from '../routes'
import { getTagPath } from '../routes'
import JsonLd from 'atoms/schemaLearningCenter'
import BlockPerson from 'molecules/block-person'
import YextContent from 'components/sections/yext'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import HubSpotForm from 'components/forms/HubSpotForm'
import LandingPageSection from 'components/sections/landing-page-section'
import MetaDataImage from '../images/meta-image.png'

// Sections
import Hero from 'organisms/hero'
import SectionTranslators from 'components/sections/translators'

// Images
import heroImageYext from '../images/yext-hero3.jpg'

import Layout from 'layouts'
// import iframeResizerMin from '../../static/iframeResizer.min'
const moment = require('../../static/vendor/moment.min.js')
const ResourceTemplate = ({ data, error }) => {
  // useEffect(() => {
  //   iframeResizerMin({ log: true }, '.pardot-responsive')
  // }, [])

  useEffect(() => {
    if (!data.page.categories.ad) {
      return false
    }
    const script = document.createElement('script')

    script.innerHTML = `
      // Inline ads.
      var inLineAds = ${JSON.stringify(data.page.categories.ad)};
      if (window.inLineAds) {
        const pList = document.querySelectorAll(".l-article__body > p");
        const insertIndex = pList.length > 3 ? Math.round(pList.length / 3) : Math.round(pList.length / 2);
        let adIndex = 0;
        Array.from(pList).forEach((p, index) => {
          if (
            index !== 0 &&
            index % insertIndex === 0 &&
            window.inLineAds.length &&
            adIndex++ < 2 &&
            adIndex <= window.inLineAds.length
          ) {
            p.insertAdjacentHTML('afterEnd', '<div className="c-inline-ad"><a href="' + window.inLineAds[adIndex - 1].url + '" class="c-inline-ad__link"><img loading="lazy" class="c-inline-ad__image" src="' + window.inLineAds[adIndex - 1].image.file.url + '" alt="' + window.inLineAds[adIndex - 1].title + '" /></a></div>')
          }
        });
      }
    `
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [data])

  const heroImage = getImage(data.page.image)
  const hubspotFormReference = data.page.hubspotFormReference || {}
  const { portalid, formid, campaignId } = hubspotFormReference

  return (
    <Layout>
      <JsonLd
        title={data.page.title}
        url={
          data.page?.categories?.key
            ? `resources/${data.page.categories.key}/${data.page.slug}/`
            : `resources/${data.page.slug}/`
        }
      />
      <Metadata
        title={data.page.metadataTitle || `${data.page.title} - Smartling`}
        description={data.page.metadataDescription}
        keywords={
          data.page.metadataKeywords?.join(`, `) ||
          (data.page.tags &&
            data.page.tags.map((tag) => {
              return ` ${tag.name}`
            }))
        }
        image={MetaDataImage}
      />

      {/* For pardot form placement in hero */}
      {(data.page?.pardotFormReference || data.page.hubspotFormReference) &&
      data.page?.pardotFormReferencePlacement === 'Hero' ? (
        <div className="c-hero__resource c-hero__resource--ebook u-background--primary">
          <div className="o-section--inner l-wrap l-container--xl">
            <div className="u-flex">
              <div className="c-hero--left">
                <h1 className="c-hero__title o-section-title--heavy">
                  {data.page.title}
                </h1>
                <p className="c-hero__description">
                  {data.page.description && data.page.description}
                </p>
              </div>
              <div className="c-hero--right">
                <div className="c-hero__pardot">
                  <h3 className="c-hero__pardot--heading">
                    Please provide your information below
                  </h3>

                  {data.page?.pardotFormReference &&
                    (data.page?.pardotFormReferencePlacement === 'Hero' ? (
                      <PardotResponsive
                        url={data.page?.pardotFormReference?.pardotFormLink}
                      />
                    ) : (
                      ``
                    ))}

                  {data.page.hubspotFormReference &&
                    (data.page?.pardotFormReferencePlacement === 'Hero' ? (
                      <div
                        id="hubspot-form-container"
                        className="hubspot-form-container"
                      >
                        <HubSpotForm
                          portalId={portalid}
                          formId={formid}
                          campaignId={campaignId || ''}
                        />
                      </div>
                    ) : (
                      ``
                    ))}

                  <p>
                    For translators interested in translating for Smartling,{' '}
                    <a href="/translator_information">Apply here</a>.
                  </p>
                  <p>
                    By registering, you agree to the processing of your personal
                    data by Smartling as described in our{' '}
                    <a href="/privacy">Privacy Policy</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {data.page.image && data.page?.pardotFormReferencePlacement !== 'Hero' ? (
        <Hero
          curveBottom
          gradientPrimary
          className={
            data.page.slug === 'yext'
              ? `o-hero--custom c-hero__resource o-kicker__${data.page.type
                  .toLowerCase()
                  .replace(' ', '-')}`
              : data.page.type
              ? `${`c-hero__resource o-kicker__${data.page.type
                  .toLowerCase()
                  .replace(' ', '-')}`}`
              : `c-hero__resource`
          }
          title={
            data.page.type === 'Case Study'
              ? `${data.page.title}`
              : data.page.title
          }
        >
          <Hero.Kicker>
            <Link to="/resources">
              <span className="u-color--white">Learning Center</span>
            </Link>
            <div className="o-divider" />
            {data.page.categories && (
              <Link to={getCategoryPath(data.page.categories.key)}>
                <span className="u-color--white">
                  {data.page.categories.name}
                </span>
              </Link>
            )}
          </Hero.Kicker>

          {data.page.slug !== 'yext' ? (
            <Hero.Description>
              {data.page.description && data.page.description}
              {data.page.displayPublishDate && (
                <span className="o-date u-font--xs">
                  {moment(data.page.publishDate).format('MMMM Do, YYYY')}
                </span>
              )}

              {data.page?.pardotFormReference &&
                (data.page?.pardotFormReferencePlacement === 'Hero' ? (
                  <PardotResponsive
                    url={data.page?.pardotFormReference?.pardotFormLink}
                  />
                ) : (
                  ``
                ))}

              {data.page.hubspotFormReference &&
                (data.page?.pardotFormReferencePlacement === 'Hero' ? (
                  <div
                    id="hubspot-form-container"
                    className="hubspot-form-container"
                  >
                    <HubSpotForm
                      portalId={portalid}
                      formId={formid}
                      campaignId={campaignId || ''}
                    />
                  </div>
                ) : (
                  ``
                ))}
            </Hero.Description>
          ) : (
            <Hero.Description>
              {data.page.description && data.page.description}
              {data.page.displayPublishDate && (
                <span className="o-date u-font--xs">
                  {moment(data.page.publishDate).format('MMMM Do, YYYY')}
                </span>
              )}

              {data.page?.pardotFormReference &&
                (data.page?.pardotFormReferencePlacement === 'Hero' ? (
                  <PardotResponsive
                    url={data.page?.pardotFormReference?.pardotFormLink}
                  />
                ) : (
                  ``
                ))}

              {data.page.hubspotFormReference &&
                (data.page?.pardotFormReferencePlacement === 'Hero' ? (
                  <div
                    id="hubspot-form-container"
                    className="hubspot-form-container"
                  >
                    <HubSpotForm
                      portalId={portalid}
                      formId={formid}
                      campaignId={campaignId || ''}
                    />
                  </div>
                ) : (
                  ``
                ))}

              {/* <div class="u-font--m">
                Featuring Jessica Birenz, Director of Planning and Project
                Management at Yext.
              </div> */}
            </Hero.Description>
          )}

          {data.page.slug !== 'yext' ? (
            <Hero.Image>
              <GatsbyImage image={heroImage} alt="Yext" />
            </Hero.Image>
          ) : (
            <Hero.Image>
              <img
                loading="lazy"
                src={heroImageYext}
                alt="Yext Woman on cell phone"
                class="u-max-width--70"
              />
            </Hero.Image>
          )}
          <Hero.Buttons>
            {data.page.buttonUrl && (
              <Button url={data.page.buttonUrl} target="_blank">
                {data.page.buttonText ? data.page.buttonText : `Learn More`}
              </Button>
            )}
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn More`}
                  </Button>
                )
              })}
            {!data.page.buttonUrl && !data.page.heroButtons && (
              <Button url="/meeting-request/">Schedule a Meeting</Button>
            )}
          </Hero.Buttons>
        </Hero>
      ) : null}

      {/* No hero image and no pardot form set to hero */}
      {!data.page.image &&
      data.page?.pardotFormReferencePlacement !== 'Hero' ? (
        <Hero
          curveCenter
          gradientPrimary
          center
          className={
            data.page.type
              ? `${`c-hero__resource--no-image o-kicker__${data.page.type
                  .toLowerCase()
                  .replace(' ', '-')}`}`
              : `c-hero__resource`
          }
          title={
            data.page.type === 'Case Study'
              ? `${data.page.title}`
              : data.page.title
          }
        >
          <Hero.Kicker>
            <Link to="/resources">
              <span className="u-color--white">Learning Center</span>
            </Link>
            <div className="o-divider" />
            {data.page.categories && (
              <Link to={getCategoryPath(data.page.categories.key)}>
                <span className="u-color--white">
                  {data.page.categories.name}
                </span>
              </Link>
            )}
          </Hero.Kicker>

          <Hero.Description>
            {data.page.description && data.page.description}
            {data.page.displayPublishDate && (
              <span className="o-date u-font--xs">
                {moment(data.page.publishDate).format('MMMM Do, YYYY')}
              </span>
            )}

            {data.page?.pardotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'Hero' ? (
                <PardotResponsive
                  url={data.page?.pardotFormReference?.pardotFormLink}
                />
              ) : (
                ``
              ))}

            {data.page.hubspotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'Hero' ? (
                <div
                  id="hubspot-form-container"
                  className="hubspot-form-container"
                >
                  <HubSpotForm
                    portalId={portalid}
                    formId={formid}
                    campaignId={campaignId || ''}
                  />
                </div>
              ) : (
                ``
              ))}
          </Hero.Description>
          <Hero.Buttons>
            {data.page.buttonUrl && (
              <Button url={data.page.buttonUrl} target="_blank">
                {data.page.buttonText ? data.page.buttonText : `Learn More`}
              </Button>
            )}
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn More`}
                  </Button>
                )
              })}
          </Hero.Buttons>
        </Hero>
      ) : null}

      {data.page.slug === 'yext' ? (
        <YextContent />
      ) : (
        <article className="l-article u-spacing--sections">
          <div className="l-wrap l-container--l u-spacing--double">
            <div className="l-article__body o-text o-text--secondary">
              {data.page?.pardotFormReference &&
                (data.page?.pardotFormReferencePlacement === 'Sidebar' ? (
                  <aside className="embedly-card">
                    <PardotResponsive
                      url={data.page?.pardotFormReference?.pardotFormLink}
                    />
                  </aside>
                ) : (
                  ``
                ))}

              {data.page.hubspotFormReference &&
                (data.page?.pardotFormReferencePlacement === 'Sidebar' ? (
                  <aside className="embedly-card">
                    <div
                      id="hubspot-form-container"
                      className="hubspot-form-container"
                    >
                      <HubSpotForm
                        portalId={portalid}
                        formId={formid}
                        campaignId={campaignId || ''}
                      />
                    </div>
                  </aside>
                ) : (
                  ``
                ))}

              {data.page.author && <BlockPerson {...data.page.author} />}
              {data.page.body && (
                <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
              )}
              {data.page.contentBlock && (
                <div className="o-text">
                  {data.page.contentBlock.title && (
                    <h2>{data.page.contentBlock.title}</h2>
                  )}

                  {data.page.contentBlock.body && (
                    <DangerousHTML>
                      {data.page.contentBlock.body.html.text}
                    </DangerousHTML>
                  )}
                </div>
              )}

              {data.page?.pardotFormReference &&
                (data.page?.pardotFormReferencePlacement === 'Below Body' ? (
                  <PardotResponsive
                    url={data.page?.pardotFormReference?.pardotFormLink}
                  />
                ) : (
                  ``
                ))}

              {data.page.hubspotFormReference &&
                (data.page?.pardotFormReferencePlacement === 'Below Body' ? (
                  <div
                    id="hubspot-form-container"
                    className="hubspot-form-container"
                  >
                    <HubSpotForm
                      portalId={portalid}
                      formId={formid}
                      campaignId={campaignId || ''}
                    />
                  </div>
                ) : (
                  ``
                ))}

              {data.page.tags && (
                <div className="c-tags">
                  <hr />
                  <div className="c-tags--inner">
                    <span className="c-tags__title">Tags:</span>
                    {data.page.tags.map((tag) => {
                      return (
                        <Link to={getTagPath(tag.key)} className="c-tags__item">
                          {tag.name}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <SectionTranslators title="Meet our Translators" />
        </article>
      )}

      {data?.page?.pageSections && data?.page?.pageSections?.length > 0 && (
        <div>
          {data.page.pageSections.map((section) => (
            <LandingPageSection {...section} />
          ))}
        </div>
      )}
    </Layout>
  )
}

export default ResourceTemplate

export const pageQuery = graphql`
  query ResourceTemplateQuery($slug: String!) {
    page: contentfulResource(slug: { eq: $slug }) {
      slug
      postType
      title
      description
      type
      categories {
        name
        key
        ad {
          title
          image {
            ...ContentfulImageFragment
          }
          url
        }
      }
      tags {
        name
        key
      }
      author {
        slug
        name
        headshot {
          ...ContentfulImageFragment
        }
        positionTitle
        company
        about {
          html: childMarkdownRemark {
            text: html
          }
        }
        metadataTitle
        metadataDescription
        metadataKeywords
      }
      image {
        gatsbyImageData(
          width: 610
          formats: [WEBP, AUTO]
          placeholder: BLURRED
          height: 343
        )
      }
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      contentBlock {
        title
        body {
          html: childMarkdownRemark {
            text: html
          }
        }
      }
      publishDate
      displayPublishDate
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      buttonText
      buttonUrl
      hubspotFormReference {
        portalid
        formid
        campaignId
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
      metadataImage {
        ...ContentfulImageFragment
      }

      # new fields
      pageSections {
        ... on ContentfulLandingPageSectionGrid {
          ...ContentfulLandingPageSectionGridFragment
        }
      }
    }
  }
`
